import InVivoError from '@/model/InVivoError';
import { useEffect, useState } from 'react';

type ResolvedValue<V> =
  | { status: 'resolving' }
  | { status: 'fulfilled'; value: V }
  | { status: 'rejected'; reason: InVivoError };

export const useResolvedValue = <V>(supplier: () => Promise<V>): ResolvedValue<V> => {
  const [state, setState] = useState<ResolvedValue<V>>({ status: 'resolving' });

  useEffect(() => {
    let isMounted = true;
    supplier()
      .then((value) => {
        if (isMounted) {
          setState({ status: 'fulfilled', value });
        }
      })
      .catch((reason) => {
        if (isMounted) {
          setState({ status: 'rejected', reason: new InVivoError('Failed to resolve value', { cause: reason }) });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [supplier]);

  return state;
};
