import { createContext, ReactNode, FC, useState, useCallback } from 'react';
import { ID } from '@/model/Common.model.ts';
import { WorkflowSetup } from '@/components/Workflow/Show/Workflow.model.ts';

export type changeAnimalCommandType =
  | { type: 'SELECT_ANIMAL'; animalId: ID }
  | { type: 'SELECT_ANIMAL_CAGE'; animalId: ID; cageId: ID }
  | { type: 'CLEAR_SELECTION' };
export type changeCageCommandType = { type: 'SELECT_CAGE'; cageId: ID } | { type: 'MARK_DECEASED' };

interface WorkflowContextResponse {
  selectedAnimal: ID | undefined;
  selectedCage: ID | 'deceased' | undefined;
  changeAnimal: (payload: changeAnimalCommandType) => void;
  changeCage: (payload: changeCageCommandType) => void;
  workflowSetup: WorkflowSetup | undefined;
  updateWorkflowSetup: (workflowSetup: WorkflowSetup) => void;
}

export const WorkflowContext = createContext<WorkflowContextResponse>({
  selectedAnimal: undefined,
  selectedCage: undefined,
  changeAnimal: () => {},
  changeCage: () => {},
  workflowSetup: undefined,
  updateWorkflowSetup: () => {},
});

export const WorkflowProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedAnimal, setSelectedAnimal] = useState<ID | undefined>();
  const [selectedCage, setSelectedCage] = useState<ID | 'deceased' | undefined>();
  const [workflowSetup, setWorkflowSetup] = useState<WorkflowSetup>();

  const changeAnimal = useCallback(
    (payload: changeAnimalCommandType) => {
      switch (payload.type) {
        case 'SELECT_ANIMAL':
          setSelectedAnimal(payload.animalId);
          break;
        case 'SELECT_ANIMAL_CAGE':
          setSelectedAnimal(payload.animalId);
          setSelectedCage(payload.cageId);
          break;
        case 'CLEAR_SELECTION':
          setSelectedAnimal(undefined);
          setSelectedCage(undefined);
      }
    },
    [setSelectedAnimal, setSelectedCage]
  );

  const changeCage = useCallback(
    (payload: changeCageCommandType) => {
      setSelectedAnimal(undefined);
      switch (payload.type) {
        case 'SELECT_CAGE':
          setSelectedCage(payload.cageId);
          break;
        case 'MARK_DECEASED':
          setSelectedCage('deceased');
      }
    },
    [setSelectedAnimal, setSelectedCage]
  );

  const updateWorkflowSetup = useCallback(
    (workflowSetup: WorkflowSetup) => {
      setWorkflowSetup(workflowSetup);
    },
    [setWorkflowSetup]
  );

  return (
    <WorkflowContext.Provider
      value={{ selectedAnimal, selectedCage, changeAnimal, changeCage, workflowSetup, updateWorkflowSetup }}
    >
      {children}
    </WorkflowContext.Provider>
  );
};
