// @ts-nocheck: converted from JS

import Checkbox from '@/components/UI/FormElements/Checkbox';
import { _isEmpty } from '@/littledash';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form@latest';
import { assembleToggleOptions } from '../SetupWorkflow.utils';
import { DragDrawerChild, DragDrawerChildContainer } from './DragDrawer';

const CheckDrawer = ({ checks, workflowData, setWorkflowState, index }) => {
  const { control } = useFormContext();

  const renderChildren = (children, parent) =>
    parent.canReorder ? (
      <DragDrawerChildContainer
        items={children}
        checks={checks}
        setWorkflowState={setWorkflowState}
        parent={parent}
        fieldIndex={index}
      />
    ) : (
      <div className="pa3">
        {children.map((child, int) => (
          <DrawerChild key={int} int={int} index={index} child={child} parent={parent} canReorder={false} />
        ))}
      </div>
    );

  const toggleOptions = assembleToggleOptions(checks, workflowData.name);
  const fieldName = `workflowSetupForm.workflowTabConfig[${index}].onSave`;

  return !_isEmpty(workflowData) ? (
    <DrawerParent key={0} check={workflowData}>
      <div>
        {workflowData?.children && renderChildren(workflowData.children, workflowData)}
        <div
          className={`ph3 w-100 flex flex-wrap justify-between items-center pt3 ${
            workflowData.name === 'observations' ? '' : 'bt b--moon-gray'
          }`}
        >
          <label className="mb0 mr2 f6">After saving</label>
          <Controller
            defaultValue="focus-search"
            name={fieldName}
            control={control}
            render={({ value, onChange }: ControllerRenderProps) => {
              return (
                <select className="mw5 mb0" value={value} onChange={onChange}>
                  <option value="focus-search">Focus search bar</option>
                  {Object.keys(toggleOptions).map((k) => (
                    <option key={k} value={toggleOptions[k].value}>
                      {toggleOptions[k].name}
                    </option>
                  ))}
                  <option value="next-subject">Move to next animal</option>
                </select>
              );
            }}
          />
        </div>
      </div>
    </DrawerParent>
  ) : null;
};

const DrawerParent = ({ check, children }) => {
  return <div>{check.value && !_isEmpty(children) && <>{children}</>}</div>;
};

const DrawerChild = ({ int, index, child, parent, canReorder, updateChecks, moveChild }) => {
  const { control } = useFormContext();
  const toggleCheckbox = () => {
    child.value = !child.value;
    updateChecks(child, parent);
  };
  const fieldName = `workflowSetupForm.workflowTabConfig[${index}].children[${int}].value`;

  const check = (
    <Controller
      defaultValue={true}
      name={fieldName}
      control={control}
      render={({ value, onChange }) => {
        return (
          <Checkbox
            type="checkbox"
            name={fieldName}
            label={child.displayName}
            checked={value}
            className="f5"
            onChange={() => onChange(!value)}
          />
        );
      }}
    />
  );
  let content = <div className="pv2">{check}</div>;
  if (canReorder) {
    content = (
      <DragDrawerChild
        index={index}
        child={child}
        parent={parent}
        toggleCheckbox={toggleCheckbox}
        moveChild={moveChild}
      />
    );
  }

  return content;
};

export default CheckDrawer;
