import Loading from '@/components/Loading';
import { SelectionReorder } from '@/components/Modals/SetupWorkflow/SelectionReorder';
import { assembleToggleOptions } from '@/components/Modals/SetupWorkflow/SetupWorkflow.utils';
import Switch from '@/components/UI/FormElements/Switch';
import type { FC } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import FormField from '../Forms/FormField';
import { useLegacyApiHook } from '@/support/Hooks/api/useLegacyApiHook.ts';
import { SampleType } from '@/model/Sample.model.ts';

const formContextPath = 'workflowSetupForm.workflowTabConfig[1]';
export const Samples: FC = () => {
  const { control, watch } = useFormContext();
  const { response: samplesGlossaryResponse, loading: samplesGlossaryLoading } = useLegacyApiHook({
    method: 'GET',
    apiRoute: 'team-glossary.list',
    query: { group: 'samples' },
    options: { onError: { toast: false, slug: 'samples-glossary-load' } },
  });
  const samples = (samplesGlossaryResponse?.body as { data: Array<SampleType> })?.data ?? [];
  const toggleOptions = assembleToggleOptions(watch('workflowSetupForm.workflowTabConfig'), 'samples');
  if (samplesGlossaryLoading) {
    return <Loading />;
  }
  return (
    <>
      <Controller
        name={`${formContextPath}.preSelectedSampleTypeIds`}
        control={control}
        render={({ value, onChange }: ControllerRenderProps) => (
          <SelectionReorder
            selectionTitle="sample"
            options={samples}
            selection={value}
            selectionChange={(selection) => onChange(selection)}
          />
        )}
      />
      <FormField label="Auto generate Sample ID">
        <Controller
          defaultValue={true}
          name={`${formContextPath}.autoGenerateSampleIds`}
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <Switch value={value} onChange={onChange} testId="auto-generate-sample-id-switch" />
          )}
        />
      </FormField>
      <FormField label="After saving">
        <Controller
          defaultValue="focus-search"
          name={`${formContextPath}.onSave`}
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <select className="mw5 mb0" value={value} onChange={onChange}>
              <option value="focus-search" data-testid="after-saving-option-focus-search-bar">
                Focus search bar
              </option>
              {Object.keys(toggleOptions).map((k) => (
                <option
                  key={k}
                  value={toggleOptions[k].value}
                  data-testid={`after-saving-option-${toggleOptions[k].value}`}
                >
                  {toggleOptions[k].name}
                </option>
              ))}
              <option value="next-subject" data-testid="after-saving-option-move-to-next-animal">
                Move to next animal
              </option>
            </select>
          )}
        />
      </FormField>
    </>
  );
};
