//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import FormField from '@/components/Modals/SetupWorkflow/Forms/FormField.tsx';
import Switch from '@/components/UI/FormElements/Switch';
import { WorkflowTabConfig } from '@/components/Workflow/Show/Workflow.model.ts';

const WorkflowConsumption = () => {
  const { control } = useFormContext<WorkflowTabConfig>();

  return (
    <div>
      <FormField label="Enable food intake tracking" enableBorder={false}>
        <Controller
          defaultValue={false}
          name="workflowSetupForm.consumptionEnabled"
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <Switch value={value} onChange={onChange} testId="enable-food-intake-switch" />
          )}
        />
      </FormField>
    </div>
  );
};

export default WorkflowConsumption;
