import DatePickerNative from '@/components/UI/DatePickerNative/DatePickerNative';
import Switch from '@/components/UI/FormElements/Switch';
import { animalIdsRecord } from '@/constants/utils';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import FormField from './FormField';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { featuresSelector } from '@/support/Selectors';
import { AnimalIdentifier } from '@/model/Animal.model.ts';

const startTabOptions = [
  { value: 'measurements', label: 'Measurements' },
  { value: 'samples', label: 'Samples' },
  { value: 'observations', label: 'Observations' },
  { value: 'dosing', label: 'Dosing' },
];

const featureSelector = createSelector([featuresSelector], (features) => ({
  playSound: features?.workflow_measurement_audible_notification ?? false,
}));

const General = () => {
  const { playSound } = useSelector(featureSelector);
  const { control } = useFormContext();

  return (
    <div>
      <FormField label="Show treatment groups" enableBorder={false}>
        <Controller
          defaultValue={false}
          name="workflowSetupForm.showGroups"
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <Switch value={value} onChange={onChange} testId="show-treatment-groups-switch" />
          )}
        />
      </FormField>

      <FormField label="Set the date you would like to record">
        <Controller
          name="workflowSetupForm.measured_at"
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => {
            return <DatePickerNative value={value} onChange={onChange} ddPrivacy="allow" />;
          }}
        />
      </FormField>
      <FormField label="ID method to search by">
        <Controller
          name="workflowSetupForm.idToSearch"
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <select
              className="w4 dib mb0"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              data-testid="id-to-search-dropdown"
            >
              {(Object.keys(animalIdsRecord) as Array<AnimalIdentifier>).map((id) => {
                return (
                  <option value={id} key={id} data-testid={`id-to-search-option-${id}`}>
                    {animalIdsRecord[id]?.title ?? `${id}`}
                  </option>
                );
              })}
            </select>
          )}
        />
      </FormField>
      <FormField label="Display identifier">
        <Controller
          name="workflowSetupForm.idToDisplay"
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <select
              value={value}
              className="w4 dib mb0"
              onChange={(e) => onChange(e.target.value)}
              data-testid="id-to-display-dropdown"
            >
              {(Object.keys(animalIdsRecord) as Array<AnimalIdentifier>).map((id) => {
                return (
                  <option value={id} key={id} data-testid={`id-to-display-option-${id}`}>
                    {animalIdsRecord[id]?.title ?? `${id}`}
                  </option>
                );
              })}
            </select>
          )}
        />
      </FormField>
      <FormField label="Start on">
        <Controller
          name="workflowSetupForm.startTab"
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <select
              className="dib mb0"
              style={{ width: '9rem' }}
              value={value}
              onChange={(changeEvent) => onChange(changeEvent.target.value)}
            >
              {startTabOptions.map(({ value, label }) => (
                <option key={value} value={value} data-testid={`start-on-option-${value}`}>
                  {label}
                </option>
              ))}
            </select>
          )}
        />
      </FormField>
      {playSound && (
        <div className="pa3 bt b--moon-gray flex justify-between flex-wrap items-center">
          <Controller
            defaultValue={true}
            name="workflowSetupForm.playSound"
            control={control}
            render={({ onChange, value }: ControllerRenderProps) => (
              <div className="mt3 flex flex-wrap justify-between w-100">
                <label className="mb0 f6">Play sound</label>
                <Switch value={value} onChange={onChange} testId="play-sound-switch" />
              </div>
            )}
          />
        </div>
      )}
    </div>
  );
};

export default General;
