import { FC, Fragment, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import styles from './GanttView.module.scss';
import { intlFormat, isToday } from 'date-fns';
import { _isNil, _noop, _notNil } from '@/littledash';
import { DateTimeRenderFormat, DateUtils } from '@/utils/Date.utils';
import cn from 'classnames';
import { StudyApiId } from '@/model/Study.model';
import { useVirtualizer } from '@tanstack/react-virtual';
import { GanttViewContext } from '@/components/Tasks/Calendar/Views/Day/GanttView.provider';
import { StudyRow } from '@/components/Tasks/Calendar/Views/Day/StudyRow';

const timeSlots = Array.from({ length: 24 }).map((_, index) => ({
  start: index * 2 + 1,
  offset: index * 30,
  label: intlFormat(new Date(0, 0, 0, 0, index * 60), { hour: 'numeric', minute: '2-digit' }),
}));
const measureElement = (element: HTMLElement) =>
  element?.querySelector('div[data-study-tasks-row]')?.clientHeight ?? 50;

export const GanttView: FC = () => {
  const { props, pagination, loadingStudies } = useContext(GanttViewContext);
  const displayCurrentTime = useMemo(() => {
    return isToday(new Date(props.dateRange.start)) && isToday(new Date(props.dateRange.end));
  }, [props.dateRange]);

  const currentTimeRef = useRef<HTMLDivElement | null>(null);
  const timeGridRef = useRef<HTMLDivElement>(null);
  const scrollPositionRef = useRef(880);
  const studiesContainerRef = useRef<HTMLDivElement>(null);
  const updateCurrentTime = useCallback(() => {
    const now = new Date();
    const currentOffset = now.getHours() * 60 + now.getMinutes();
    const gridColumn = Math.floor(currentOffset / 30) + 1;
    const percentage = (((now.getMinutes() + now.getSeconds() / 60) % 30) / 30) * 100;
    if (_notNil(currentTimeRef.current)) {
      currentTimeRef.current.style.marginLeft = `${percentage}%`;
      currentTimeRef.current.style.gridColumn = `${gridColumn}`;
      currentTimeRef.current.setAttribute(
        'data-tooltip-content',
        DateUtils.renderDateTime(now.toISOString(), { format: DateTimeRenderFormat.TimeWithSeconds })
      );
    }
  }, [currentTimeRef]);

  useEffect(() => {
    if (displayCurrentTime) {
      updateCurrentTime();
      const intervalId = setInterval(() => updateCurrentTime(), 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [displayCurrentTime, updateCurrentTime]);
  useEffect(() => {
    if (_notNil(studiesContainerRef.current) && _notNil(timeGridRef.current)) {
      const abort = new AbortController();
      studiesContainerRef.current.addEventListener(
        'scroll',
        (event) => {
          scrollPositionRef.current = (event.target as HTMLDivElement).scrollLeft;
          if (_notNil(timeGridRef.current)) {
            timeGridRef.current.style.transform = `translateX(-${(event.target as HTMLDivElement).scrollLeft}px)`;
          }
        },
        { signal: abort.signal, passive: true }
      );
      return () => abort.abort();
    }
  }, [timeGridRef, studiesContainerRef, scrollPositionRef]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (_notNil(studiesContainerRef.current)) {
        studiesContainerRef.current?.scrollTo?.({ left: scrollPositionRef.current, behavior: 'instant' });
      }
    }, 100);
    return () => clearTimeout(timeoutId);
  }, [studiesContainerRef, scrollPositionRef, props.dateRange]);

  const { studyApiIds, mode } = useMemo(() => {
    const studyApiIds = Object.keys(props.studyColours ?? {}) as Array<StudyApiId>;
    if (studyApiIds.length > 0) {
      return { studyApiIds, mode: 'filter' };
    }
    return { mode: 'pagination', studyApiIds: Array.from(pagination.items.keys()) };
  }, [props.studyColours, pagination.items]);
  const studyVirtualizer = useVirtualizer({
    count: mode === 'filter' ? studyApiIds.length : pagination.total,
    getScrollElement: () => studiesContainerRef.current,
    estimateSize: () => 50,
    measureElement,
    overscan: 5,
  });
  const virtualRows = studyVirtualizer.getVirtualItems();
  useEffect(() => {
    if (mode === 'pagination') {
      const lastRow = virtualRows.at(-1);
      if (_notNil(lastRow) && lastRow.index >= studyApiIds.length && pagination.hasNext && !loadingStudies) {
        pagination.next().catch(_noop);
      }
    }
  }, [pagination.hasNext, pagination.next, mode, studyApiIds.length, loadingStudies, virtualRows]);

  return (
    <div className={styles.GanttContainer} data-test-component="GanttView" data-test-element="container">
      <div className={cn('br b--gray0', styles.StudyHeaderPlaceholder)}></div>
      <div ref={timeGridRef} className={cn('pv3', styles.TimeGridContainer)}>
        {timeSlots.map((timeSlot) => (
          <Fragment key={`time_slot_${timeSlot.start}`}>
            <div className={styles.Header} style={{ gridRow: '1 / 2', gridColumn: 'span 2' }}>
              <span className={styles.Label}>{timeSlot.label}</span>
            </div>
            <div
              className={styles.Body}
              style={{ gridRow: '2 / 2', gridColumn: `${timeSlot.start} / ${timeSlot.start + 1}` }}
            />
          </Fragment>
        ))}
        {displayCurrentTime ? (
          <div ref={currentTimeRef} className={styles.CurrentTime} data-tooltip-id="global-tooltip-id" />
        ) : null}
      </div>
      <div className={styles.StudiesContainer}>
        <div
          ref={studiesContainerRef}
          data-test-element="studies-container"
          style={{
            height: `100%`,
            width: `100%`,
            overflow: 'auto',
          }}
        >
          <div
            style={{
              height: `${studyVirtualizer.getTotalSize()}px`,
              width: '100%',
              position: 'relative',
            }}
          >
            {virtualRows.map((studyRow) =>
              _notNil(studyApiIds[studyRow.index]) ? (
                <div
                  key={studyRow.key}
                  data-index={studyRow.index}
                  ref={studyVirtualizer.measureElement}
                  data-test-element="study-row"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: `${studyRow.size}px`,
                    transform: `translateY(${studyRow.start}px)`,
                  }}
                >
                  <StudyRow
                    studyApiId={studyApiIds[studyRow.index]}
                    color={props.studyColours?.[studyApiIds[studyRow.index]]}
                  />
                </div>
              ) : null
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
