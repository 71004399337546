import { CSSProperties, FC, MouseEventHandler, useMemo } from 'react';
import { TaskV1 } from '@/model/Task.model';
import { TaskPosition } from '@/components/Tasks/Calendar/Views/Day/GanttView.utils';
import type { ColorDetail } from '@/constants/colors';
import { _notNil } from '@/littledash';
import cn from 'classnames';
import styles from '@/components/Tasks/Calendar/Views/Day/GanttView.module.scss';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import { DateTimeRenderFormat } from '@/utils/Date.utils';

export const TaskCard: FC<{
  task: TaskV1;
  position?: TaskPosition;
  color?: ColorDetail;
  onClick?: MouseEventHandler<HTMLDivElement>;
}> = ({ task, position, color, onClick }) => {
  const gridStyle = useMemo<CSSProperties>(() => {
    if (_notNil(position)) {
      return {
        gridRow: `${position.row + 1}/${position.row + 1}`,
        gridColumn: `${position.start + 1}/${position.end + 1}`,
        borderColor: color?.color,
      };
    }
    return {};
  }, [position, color]);
  return (
    <div
      className="ba"
      style={gridStyle}
      onClick={onClick}
      data-test-component="TestCard"
      data-test-element="container"
      data-test-key={task.id}
    >
      <div className={cn('flex flex-row', styles.TaskCard, color?.background?.class)}>
        <div className="pa2">
          <div className={styles.ColorChip} style={{ backgroundColor: color?.color ?? 'white' }}></div>
        </div>
        <div className="flex flex-column pv2">
          <div className={styles.Title}>{task.title}</div>
          <div className="f8 dark-gray">
            <span>
              <DateTimeRenderer
                value={task.duration.start}
                timezone={task.timezone}
                format={DateTimeRenderFormat.Time}
              />
            </span>
            <span> - </span>
            <span>
              <DateTimeRenderer value={task.duration.end} timezone={task.timezone} format={DateTimeRenderFormat.Time} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
