import NoDataCard from '@/components/NoDataCard';

export const WorkflowNoCageDataCard = (
  <NoDataCard
    title="Cage data will appear here"
    text="Food/fluid consumed amount per day will appear here once first calculation is made."
    dark
    className="pb3 mr4 ml4"
  />
);
