import Loading from '@/components/Loading';
import { SelectionReorder } from '@/components/Modals/SetupWorkflow/SelectionReorder';
import { assembleToggleOptions } from '@/components/Modals/SetupWorkflow/SetupWorkflow.utils';
import type { FC } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { useLegacyApiHook } from '@/support/Hooks/api/useLegacyApiHook.ts';
import { Observation } from '@/model/Observation.model.ts';

const formContextPath = 'workflowSetupForm.workflowTabConfig[2]';

export const Observations: FC = () => {
  const { control, watch } = useFormContext();
  const { response: observationsGlossaryResponse, loading: observationsGlossaryLoading } = useLegacyApiHook({
    method: 'GET',
    apiRoute: 'team-glossary.list',
    query: { group: 'observations' },
    options: { onError: { toast: false, slug: 'observations-glossary-load' } },
  });
  const observations = (observationsGlossaryResponse?.body as { data: Array<Observation> })?.data ?? [];
  const toggleOptions = assembleToggleOptions(watch('workflowSetupForm.workflowTabConfig'), 'observations');
  if (observationsGlossaryLoading) {
    return <Loading />;
  }
  return (
    <>
      <Controller
        name={`${formContextPath}.preSelectedObservationTypeIds`}
        control={control}
        render={({ value, onChange }: ControllerRenderProps) => (
          <SelectionReorder
            selectionTitle="observation"
            options={observations}
            selection={value}
            selectionChange={(selection) => onChange(selection)}
          />
        )}
      />
      <div className="pa3 bt b-moon-gray flex justify-between items-center w-100">
        <label className="mb0 f6">After saving</label>
        <Controller
          defaultValue="focus-search"
          name={`${formContextPath}.onSave`}
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <select className="mw5 mb0" value={value} onChange={onChange}>
              <option value="focus-search" data-testid="after-saving-option-focus-search-bar">
                Focus search bar
              </option>
              {Object.keys(toggleOptions).map((k) => (
                <option
                  key={k}
                  value={toggleOptions[k].value}
                  data-testid={`after-saving-option-${toggleOptions[k].value}`}
                >
                  {toggleOptions[k].name}
                </option>
              ))}
              <option value="next-subject" data-testid="after-saving-option-move-to-next-animal">
                Move to next animal
              </option>
            </select>
          )}
        />
      </div>
    </>
  );
};
