import { TaskApiId, TaskV1 } from '@/model/Task.model';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { _noop } from '@/littledash';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import InVivoError from '@/model/InVivoError';

export type TaskPosition = {
  id: TaskApiId;
  row: number;
  start: number;
  end: number;
  duration: number;
};
export const retry = <T>(action: () => Promise<T>, maxRetries: number = 3): Promise<T> => {
  return action().catch((err) => {
    if (maxRetries <= 1) {
      return Promise.reject(err);
    }
    return retry(action, maxRetries - 1);
  });
};

/**
 * Packs tasks into a map of task positions.
 *
 * Varient of [ISMP](https://en.wikipedia.org/wiki/Interval_scheduling) algorithm for packing tasks into rows.
 *
 * @param {Array<TaskV1>} tasks - The array of tasks to be packed.
 * @returns {Map<string, TaskPosition>} - A map of task grid positions.
 */
export const packTasks = (tasks: Array<TaskV1>): Map<string, TaskPosition> => {
  const { taskPositions } = tasks.reduce<{
    taskPositions: Map<string, TaskPosition>;
  }>(
    (acc, task) => {
      const startDate = new Date(task.duration.start);
      const endDate = new Date(task.duration.end);
      const startHours = startDate.getHours();
      const startMinutes = startDate.getMinutes();
      const start = Math.max(0, startHours * 2 + Math.floor(startMinutes / 30));
      const duration = Math.ceil((endDate.getTime() - startDate.getTime()) / (30 * 60000));
      const end = Math.min(48, start + duration);
      acc.taskPositions.set(task.id, { id: task.id, row: -1, start, end, duration });
      return acc;
    },
    { taskPositions: new Map<string, TaskPosition>() }
  );
  const rows: Array<Array<number>> = [];
  Array.from(taskPositions.values())
    .sort((left, right) => (left.start === right.start ? right.duration - left.duration : left.start - right.start))
    .forEach((taskPosition) => {
      let selectedRow = -1;
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        if (row.every((end) => end <= taskPosition.start)) {
          selectedRow = i;
          break;
        }
      }
      if (selectedRow === -1) {
        selectedRow = rows.length;
        rows.push([taskPosition.end]);
      } else {
        rows[selectedRow].push(taskPosition.end);
      }
      (taskPositions.get(taskPosition.id) as TaskPosition).row = selectedRow;
    });
  return taskPositions;
};
type PageLoadResponse<T> = {
  data: Array<T>;
  page: number;
  last_page: number;
};

type PageLoaderProps<T> = {
  loadPage: (page: number) => Promise<PageLoadResponse<T>>;
};
type PageLoaderResponse<T> = {
  data: Array<T>;
};
export const usePageLoader = <T>({ loadPage }: PageLoaderProps<T>): PageLoaderResponse<T> => {
  const [data, setData] = useState<Record<number, Array<T>>>({});
  const fetch = useCallback(
    (page: number) =>
      retry(() => loadPage(page)).then((response) => {
        setData((previous) => ({ ...previous, [response.page]: response.data }));
        return response;
      }),
    [setData, loadPage]
  );
  const reload = useCallback(async () => {
    try {
      setData({});
      const pageOne = await fetch(1);
      await Promise.allSettled(
        Array.from({ length: (pageOne.last_page ?? 1) - 1 }).map((_, idx) => retry(() => fetch(idx + 2)))
      );
    } catch (cause) {
      ExceptionHandler.captureException(
        new InVivoError('Failed to load all pages', {
          cause,
          slug: 'use-page-loader',
        })
      );
    }
  }, [fetch]);
  useEffect(() => {
    reload();
  }, []);

  return useMemo(
    () => ({
      reload,
      data: Object.entries(data)
        .sort((left, right) => Number(left[0]) - Number(right[0]))
        .flatMap((entry) => entry[1]),
    }),
    [data, reload]
  );
};
