// @ts-nocheck: converted from JS

import { FilterTypes as filterType } from '@/constants/FilterTypes';
import { _get, _isEmpty, _notNil, _size } from '@/littledash';
import { AlertState, ShowStateReducer } from './Workflow.model';
import { animalIdsRecord } from '@/constants/utils.ts';
import { updateQueryParams } from '@/support/RouteService.util.ts';

export const assembleWorkflowFilterOptions = ({ study, collections }: { study: Study; collections: Array<Cage> }) => {
  const { study_groups: studyGroups } = study;
  const calculations = study.settings.calculations;

  const studyGroupIds = studyGroups.map(({ name, id }) => ({
    name,
    value: id,
  }));
  const cageIds = collections.map(({ name, id }) => ({
    name,
    value: id,
  }));
  const variableFilters = calculations.map(({ name, id }) => ({
    name,
    value: id,
    type: 'latestMeasurements',
    operations: [
      {
        name: 'greater than',
        value: filterType.gt,
      },
      {
        name: 'less than',
        value: filterType.lt,
      },
      {
        name: filterType.IS_NULL,
        value: filterType.IS_NULL,
      },
      {
        name: filterType.IS_NOT_NULL,
        value: filterType.IS_NOT_NULL,
      },
    ],
  }));

  let filters = {};
  if (_size(cageIds)) {
    filters = [
      {
        value: 'cage',
        name: 'Cage Name',
        operations: [
          {
            name: 'is equal to',
            value: filterType.eq,
            options: cageIds,
          },
          {
            name: 'is not equal to',
            value: filterType.ne,
            options: cageIds,
          },
        ],
      },
      {
        value: 'deceased',
        name: 'Living status',
        operations: [
          {
            name: 'is equal to',
            value: filterType.eq,
            options: [{ value: 'null', name: 'Alive' }],
          },
          {
            name: 'is not equal to',
            value: filterType.ne,
            options: [{ value: 'null', name: 'Alive' }],
          },
        ],
      },
      {
        value: 'sex',
        name: 'Sex',
        operations: [
          {
            name: 'is equal to',
            value: filterType.eq,
            options: [
              { value: 'm', name: 'Male' },
              { value: 'f', name: 'Female' },
            ],
          },
        ],
      },
      {
        value: 'alerts',
        name: 'Alert',
        operations: [
          {
            name: 'is',
            value: filterType.eq,
            options: [
              { value: 'alert_unresolved', name: 'Unresolved' },
              { value: 'alert_resolved', name: 'Resolved' },
            ],
          },
        ],
      },
      ...variableFilters,
    ];
    if (studyGroups.length) {
      filters.push({
        value: 'study_group',
        name: 'Study Group',
        operations: [
          {
            name: 'is equal to',
            value: filterType.eq,
            options: studyGroupIds,
          },
          {
            name: 'is not equal to',
            value: filterType.ne,
            options: studyGroupIds,
          },
        ],
      });
    }
  }
  return filters;
};

export const getNextAnimal = (subjectId, subjects, direction = 'desc') => {
  let nextAnimalIndex;

  const currentIndex = subjects.findIndex((subject) => subject.id === subjectId);

  if (direction === 'desc') {
    nextAnimalIndex = currentIndex + 1 === subjects.length ? 0 : currentIndex + 1;
  } else {
    nextAnimalIndex = currentIndex === 0 ? subjects.length - 1 : currentIndex - 1;
  }

  return subjects[nextAnimalIndex];
};

export const reducer: ShowStateReducer = (state, action) => {
  switch (action.type) {
    case 'setSubjects': {
      return { ...state, originalSubjects: action.data, subjects: action.data };
    }
    case 'setCollections': {
      return { ...state, collections: action.data };
    }
    case 'setFilterOptions': {
      return { ...state, filterOptions: action.data };
    }
    case 'searchQuery': {
      const { originalSubjects } = state;

      action.data.changeAnimal({ type: 'CLEAR_SELECTION' });

      if (_isEmpty(action.data.searchQuery)) {
        return {
          ...state,
          searchQuery: '',
          subjects: originalSubjects,
        };
      }
      const filteredSubjects = originalSubjects.filter((animal) => {
        const altRecordKey = animalIdsRecord[action.data.idToSearch].animalIdKey;
        return _get(animal, altRecordKey)?.toLowerCase().includes(action.data.searchQuery.toLowerCase());
      });

      const update = {
        ...state,
        searchQuery: action.data.searchQuery,
        subjects: filteredSubjects,
      };

      if (filteredSubjects.length === 1) {
        const onlySubject = filteredSubjects[0];
        action.data.changeAnimal({ type: 'SELECT_ANIMAL_CAGE', animalId: onlySubject.id, cageId: onlySubject.cage_id });
      }
      return update;
    }
    case 'updateChecklist':
      return {
        ...state,
        checklist: action.data,
      };
    case 'updateAlerts': {
      const animalAlerts = action.data.reduce<AlertState['animalAlerts']>((acc, alert) => {
        if (_notNil(alert?.id) && _notNil(alert?.subject_id)) {
          const alertResolved = _notNil(alert?.resolved_at);
          const alertData = acc?.[alert.subject_id]?.[alert.id];
          if (alertData !== alertResolved) {
            return {
              ...acc,
              [alert.subject_id]: { ...(acc?.[alert.subject_id] ?? {}), [alert.id]: alertResolved },
            };
          }
        }
        return acc;
      }, state.alerts.animalAlerts);

      if (state.alerts.animalAlerts !== animalAlerts) {
        const total = Object.values(animalAlerts).reduce(
          (acc, alertsMap) => acc + Object.values(alertsMap).filter((resolved) => !resolved).length,
          0
        );
        return { ...state, alerts: { animalAlerts, total } };
      }
      return state;
    }
    case 'resetAlerts': {
      return {
        ...state,
        alerts: action.data,
      };
    }
    case 'update':
      return {
        ...state,
        ...action.data,
      };
    case 'updateSubject': {
      const subjectIndex = state.subjects.findIndex(({ id }) => id === action.data.id);
      const originalSubjectIndex = state.originalSubjects.findIndex(({ id }) => id === action.data.id);
      if (subjectIndex >= 0) {
        state.subjects[subjectIndex] = action.data;
      }
      if (originalSubjectIndex >= 0) {
        state.originalSubjects[originalSubjectIndex] = action.data;
      }
      return { ...state, subjects: [...state.subjects] };
    }
    default:
      return state;
  }
};

const getFilterOperation = (name) => Object.keys(filterType).find((key) => filterType[key] === name);

export const generateFilterParams = (filters: Array<unknown>, filterMatch: unknown): string => {
  const newFilterParamsObj = new URLSearchParams({
    filterType: filterMatch ? 'and' : 'or',
  });
  filters.forEach((filter) => {
    if (!filter.category.value.includes('metadata.') && filter.option.value !== '') {
      updateQueryParams(newFilterParamsObj, {
        'filters[]': `${filter.category.value}|${getFilterOperation(filter.operation.name)}|${filter.option.value}`,
      });
    }
  });
  return newFilterParamsObj.toString();
};
