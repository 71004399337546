import { useCallback, useRef } from 'react';
import InVivoError from '@/model/InVivoError';
import { _isNil } from '@/littledash';

export type UseCacheResponse<K, V> = {
  get: (key: K, force?: boolean) => Promise<V>;
  reset: () => void;
};
export type CacheLoadResponse<K, V> = {
  key: K;
  value: V;
  cache: boolean;
};
type UseCacheProps<K, V> = {
  load: (key: K) => Promise<CacheLoadResponse<K, V>>;
};

export const useCache = <K, V>({ load }: UseCacheProps<K, V>): UseCacheResponse<K, V> => {
  const cache = useRef(new Map<K, V>());

  const get = useCallback<UseCacheResponse<K, V>['get']>(
    (key: K, force = false) => {
      if (_isNil(key)) {
        return Promise.reject(new InVivoError('Invalid request: null key', { slug: 'use-cache' }));
      }
      if (!force && cache.current.has(key)) {
        return Promise.resolve(cache.current.get(key)!);
      }
      if (typeof load === 'function') {
        return load(key)
          .then((loadResponse) => {
            if (loadResponse.cache) {
              cache.current.set(loadResponse.key, loadResponse.value);
            }
            return loadResponse.value;
          })
          .catch((cause) => Promise.reject(new InVivoError('Load function failed', { cause, slug: 'use-cache' })));
      }
      return Promise.reject(new InVivoError('Invalid load or key function', { slug: 'use-cache' }));
    },
    [load, cache]
  );

  return {
    get,
    reset: () => cache.current.clear(),
  };
};
