import { createContext, FC, PropsWithChildren } from 'react';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { usePagination, UsePaginationOutput } from '@/support/Hooks/api/usePagination';
import { StudyApiId, StudyInfoV1 } from '@/model/Study.model';
import InVivoError from '@/model/InVivoError';
import type { CalendarFilters, DateRange, StudyColours } from '@/components/Tasks/Calendar/Calendar.model';
import { useCache, UseCacheResponse } from '@/support/Hooks/api/useCache';
import { _notNil } from '@/littledash';

export type GanttViewContext = {
  props: GanttViewProps;
  loadingStudies: boolean;
  pagination: UsePaginationOutput<StudyApiId, StudyInfoV1>;
  studyInfoCache: UseCacheResponse<StudyApiId, StudyInfoV1>;
};

export const GanttViewContext = createContext<GanttViewContext>({} as GanttViewContext);
type GanttViewProps = {
  dateRange: DateRange;
  filters: CalendarFilters;
  studyColours: StudyColours;
  narrow?: boolean;
};
export const GanttViewProvider: FC<PropsWithChildren<GanttViewProps>> = ({ children, ...props }) => {
  const { invoke: loadStudies, loading: loadingStudies } = useApiHook({
    endpoint: 'GET /api/v1/studies',
    options: { onError: { slug: 'gantt-study-list' } },
    invokeOnInit: false,
  });
  const { invoke: loadStudyInfo } = useApiHook({
    endpoint: 'GET /api/v1/studies/{studyApiId}/info',
    options: { onError: { slug: 'gantt-study-info-get', toast: false, throw: false } },
    invokeOnInit: false,
  });
  const pagination = usePagination<StudyApiId, StudyInfoV1>({
    query: (page: number) =>
      loadStudies({ query: { page } }).then((response) => {
        if (response.type === 'success') {
          return {
            data: response.body.data,
            state: {
              total: response.body.meta.total ?? 0,
              current_page: response.body.meta.current_page ?? 0,
              last_page: response.body.meta.last_page ?? 0,
            },
          };
        }
        throw new InVivoError('Could not load studies', { cause: response.error, slug: 'paginated-study-load' });
      }),
    key: (item) => item.api_id,
  });
  const studyInfoCache = useCache<StudyApiId, StudyInfoV1>({
    load: (key) => {
      const value = pagination.items.get(key);
      if (_notNil(value)) {
        return Promise.resolve({ key, value, cache: false });
      }
      return loadStudyInfo({ path: { studyApiId: key } }).then((response) => {
        return response.type === 'success'
          ? { key: response.body.api_id, value: response.body, cache: true }
          : Promise.reject(response.error);
      });
    },
  });

  return (
    <GanttViewContext.Provider value={{ loadingStudies, props, pagination, studyInfoCache }}>
      {children}
    </GanttViewContext.Provider>
  );
};
