import { FC, useCallback, useContext, useMemo } from 'react';
import { StudyApiId } from '@/model/Study.model';
import type { ColorDetail } from '@/constants/colors';
import { GanttViewContext } from '@/components/Tasks/Calendar/Views/Day/GanttView.provider';
import { useModalAction } from '@/utils/modal';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { packTasks, retry, TaskPosition } from '@/components/Tasks/Calendar/Views/Day/GanttView.utils';
import { TaskV1 } from '@/model/Task.model';
import { useResolvedValue } from '@/support/Hooks/api/useResolvedValue';
import styles from '@/components/Tasks/Calendar/Views/Day/GanttView.module.scss';
import { TaskCard } from '@/components/Tasks/Calendar/Views/Day/TaskCard';
import type { Endpoints } from '@/generated/internal-api/Endpoints';

type FetchTasksQueryParameters = Endpoints['GET /api/v1/tasks']['query'];
export const StudyRow: FC<{ studyApiId: StudyApiId; color?: ColorDetail }> = ({ studyApiId, color }) => {
  const context = useContext(GanttViewContext);
  const { openModal } = useModalAction();
  const baseQueryParameters = useMemo(
    () =>
      ({
        assignee: context.props.filters.assignee,
        status: context.props.filters.status,
        study_api_id: [studyApiId],
        sort: 'start',
        order: 'asc',
        perPage: 50,
        start: context.props.dateRange.start,
        end: context.props.dateRange.end,
      }) as FetchTasksQueryParameters,
    [studyApiId, context.props]
  );

  const { invoke: fetchTasks } = useApiHook({
    endpoint: 'GET /api/v1/tasks',
    query: baseQueryParameters,
    invokeOnInit: false,
    options: { onError: { slug: 'gantt-study-tasks', toast: false, capture: false, throw: true } },
  });

  const taskSupplier = useCallback(async () => {
    const pageOne = await retry(() => fetchTasks({ query: { ...baseQueryParameters, page: 1 } }));
    if (pageOne.type === 'error') {
      return Promise.reject(pageOne.error);
    }
    return Promise.allSettled([
      Promise.resolve(pageOne),
      ...Array.from({ length: (pageOne.body.meta.last_page ?? 1) - 1 }).map((_, idx) =>
        retry(() => fetchTasks({ query: { ...baseQueryParameters, page: idx + 2 } }))
      ),
    ]).then((pageResponses) =>
      pageResponses.reduce<Array<TaskV1>>(
        (acc, pageResponse) =>
          pageResponse.status === 'fulfilled' && pageResponse.value.type === 'success'
            ? [...acc, ...pageResponse.value.body.data]
            : acc,
        []
      )
    );
  }, [
    context.props.filters.assignee,
    context.props.filters.status,
    context.props.dateRange.start,
    context.props.dateRange.end,
  ]);
  const studyInfoValue = useResolvedValue(
    useCallback(() => context.studyInfoCache.get(studyApiId), [context.studyInfoCache, studyApiId])
  );
  const tasksValue = useResolvedValue(taskSupplier);
  const taskPositions = useMemo(() => {
    if (tasksValue.status === 'fulfilled') {
      return packTasks(tasksValue.value);
    }
    return new Map<string, TaskPosition>();
  }, [tasksValue]);
  const taskSwimLaneCount = useMemo(
    () => Array.from(taskPositions.values()).reduce((acc, { row }) => (row > acc ? row : acc), 0),
    [taskPositions]
  );

  return (
    <div
      className={styles.Study}
      data-study-tasks-row={studyApiId}
      style={{ height: `${(taskSwimLaneCount + 1) * 50}px` }}
    >
      <div className={styles.Header}>
        <div className="flex flex-row justify-start items-center w-100">
          <div
            className="ma2"
            style={{ backgroundColor: color?.color ?? 'white', width: '20px', height: '20px', borderRadius: '4px' }}
          />
          <div>{studyInfoValue?.status === 'fulfilled' ? studyInfoValue.value.name : null}</div>
        </div>
      </div>
      <div className={styles.Grid}>
        {tasksValue.status === 'fulfilled'
          ? tasksValue.value.map((task) => (
              <TaskCard
                key={task.id}
                task={task}
                position={taskPositions.get(task.id)}
                color={color}
                onClick={() => {
                  openModal('TASK_DETAILS', {
                    taskId: task.id,
                  });
                }}
              />
            ))
          : null}
        <div className={styles.StudyRowTest}></div>
      </div>
    </div>
  );
};
