import { _notNil } from '@/littledash';
import { useDevices } from '@/utils/devices/useDevices';

export const FollowFocusConsumer = () => {
  const onNewReading = (reading: string) => {
    const activeElement = document.activeElement as HTMLInputElement;

    if (activeElement?.tagName === 'INPUT') {
      forceReactInputUpdate(activeElement, reading);

      setTimeout(() => {
        const event = new KeyboardEvent('keydown', {
          code: 'Enter',
          key: 'Enter',
          charCode: 13,
          keyCode: 13,
          view: window,
          bubbles: true,
        });

        activeElement?.dispatchEvent(event);
      }, 200);
    }
    nextReading(true);
  };

  function forceReactInputUpdate(input: HTMLInputElement, value: string) {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;

    if (nativeInputValueSetter) {
      nativeInputValueSetter.call(input, value);

      const inputEvent = new Event('input', { bubbles: true });
      input.dispatchEvent(inputEvent);

      const changeEvent = new Event('change', { bubbles: true });
      input.dispatchEvent(changeEvent);
    }
  }

  const { nextReading } = useDevices({
    targetId: 'follow-focus',
    onNewReading,
  });
  return false;
};
