import { ID } from '@/model/Common.model.ts';
import { useContext } from 'react';
import { WorkflowContext, changeAnimalCommandType, changeCageCommandType } from '@/utils/workflow/WorkflowProvider.tsx';
import { WorkflowSetup } from '@/components/Workflow/Show/Workflow.model.ts';

interface UseSelectedAnimalResponse {
  selectedAnimal: ID | undefined;
}

interface UseSelectedCageResponse {
  selectedCage: ID | 'deceased' | undefined;
}

interface UseChangeAnimalResponse {
  changeAnimal: (payload: changeAnimalCommandType) => void;
  changeCage: (payload: changeCageCommandType) => void;
}

interface UseWorkflowSetupResponse {
  workflowSetup: WorkflowSetup | undefined;
  updateWorkflowSetup: (workflowSetup: WorkflowSetup) => void;
}

export const useSelectedAnimal = (): UseSelectedAnimalResponse => {
  const { selectedAnimal } = useContext(WorkflowContext);
  return { selectedAnimal };
};

export const useSelectedCage = (): UseSelectedCageResponse => {
  const { selectedCage } = useContext(WorkflowContext);
  return { selectedCage };
};

export const useChangeAnimalActions = (): UseChangeAnimalResponse => {
  const { changeAnimal, changeCage } = useContext(WorkflowContext);
  return { changeAnimal, changeCage };
};

export const useWorkflowSetup = (): UseWorkflowSetupResponse => {
  const { workflowSetup, updateWorkflowSetup } = useContext(WorkflowContext);
  return { workflowSetup, updateWorkflowSetup };
};
