import { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';
import { components } from '@/generated/internal-api/openapi-types';
import { RequiredNonNil } from '@/model/Common.model';
import InVivoError from '@/model/InVivoError';
import { _noop, _notNil } from '@/littledash';

type PaginationState = Pick<
  RequiredNonNil<components['schemas']['CollectionMeta.schema']>,
  'total' | 'current_page' | 'last_page'
>;
export type PaginationResponse<V> = {
  data: Array<V>;
  state: PaginationState;
};
type UsePaginationOptions = {
  onInit?: 'load_first_page' | 'do_nothing';
};
export type UsePaginationProps<K, V> = {
  key: (item: V) => K;
  query: (page: number) => Promise<PaginationResponse<V>>;
  options?: UsePaginationOptions;
};
export type UsePaginationOutput<K, V> = {
  items: Map<K, V>;
  total: number;
  hasNext: boolean;
  next: () => Promise<void>;
  reset: () => void;
};
const promiseWithResolvers = <T>(): {
  promise: Promise<T>;
  resolve: (value: T) => void;
  reject: (reason: InVivoError) => void;
} => {
  let resolve;
  let reject;
  const promise = new Promise<T>((res, rej) => {
    resolve = res;
    reject = rej;
  });
  return { promise, resolve: resolve!, reject: reject! };
};

export const usePagination = <K, V>(props: UsePaginationProps<K, V>): UsePaginationOutput<K, V> => {
  const paginationId = useId();

  const cache = useRef(new Map<K, V>());

  const [paginationState, updatePaginationState] = useState<PaginationState>({
    total: -1,
    current_page: -1,
    last_page: -1,
  });

  const next = useCallback(async () => {
    const { resolve, reject, promise } = promiseWithResolvers<void>();

    try {
      const page = paginationState.current_page <= 0 ? 1 : paginationState.current_page + 1;
      const execute = async () => {
        try {
          if (typeof props?.query === 'function') {
            const response = await props.query(page);
            updatePaginationState(response.state);
            response.data.forEach((item) => cache.current.set(props.key(item), item));
            resolve();
          }
        } catch (e) {
          reject(new InVivoError('Could not fetch next page', { cause: e, slug: 'use-pagination' }));
        }
      };
      if (_notNil(navigator.locks)) {
        await navigator.locks.request(
          `use-pagination-${paginationId}-${page}`,
          { mode: 'exclusive', ifAvailable: true },
          async (lock) => {
            if (_notNil(lock)) {
              await execute();
            }
          }
        );
      } else {
        // Fallback for browsers that do not support the Locks API
        await execute();
      }
    } catch (e) {
      reject(new InVivoError('Could not acquire lock', { cause: e, slug: 'use-pagination' }));
    }
    return promise;
  }, [paginationId, props, paginationState, cache, updatePaginationState]);
  useEffect(() => {
    if (paginationState.current_page === -1 && (props.options?.onInit ?? 'load_first_page') === 'load_first_page') {
      next().catch(_noop);
    }
  }, [paginationState, next, props.options]);

  const items = useMemo(() => new Map(cache.current), [cache.current.size]);

  const reset = useCallback(() => {
    cache.current.clear();
    updatePaginationState({ total: -1, current_page: -1, last_page: -1 });
  }, [cache, updatePaginationState]);
  return {
    next,
    reset,
    total: paginationState.total,
    hasNext: paginationState.current_page === -1 || paginationState.current_page < paginationState.last_page,
    items,
  };
};
